const SiteCategories = {
    "abused_drugs": "Abused drugs",
    "alcohol_and_tobacco": "Alcohol and tobacco",
    "business": "Business",
    "chat": "Chat",
    "dating": "Dating",
    "educational" : "Educational",
    "entertainment" : "Entertainment",
    "file_sharing" : "File sharing",
    "forums" : "Forums",
    "gambling" : "Gambling",
    "games": "Games",
    "government": "Government",
    "health": "Health",
    "job_search": "Job search",
    "legal": "Legal",
    "news": "News",
    "site_and_blogs": "Personal sites and blogs",
    "phishing": "Phishing",
    "pornography": "Pornography",
    "profanity": "Profanity",
    "religion": "Religion",
    "search_portals": "Search portals",
    "sex_education": "Sex education",
    "shopping": "Shopping",
    "social_network": "Social network",
    "sports": "Sports",
    "technology": "Technology",
    "travel": "Travel",
    "uncategorized": "Uncategorized",
    "violence": "Violence",
    "weapons": "Weapons",
    "webmail": "Webmail",
}

export default SiteCategories;