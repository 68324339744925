import "./styles.scss";
import {Button, FormControl, FormHelperText, FormLabel, Grid, Input} from "@mui/joy";
import {toast} from "react-toastify";
import {useState} from "react";
import {UserAPI} from "@api";

export const LoginScreen = () => {
    const [uname, setUname] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async () => {
        try {
            let loginResult = await UserAPI.auth(uname, password);

            localStorage.setItem("@token", loginResult.data.token);
            localStorage.setItem("@uid", loginResult.data._id);
            localStorage.setItem("@uname", loginResult.data.uname);

            window.location.href = "/";
        }catch(e) {
            toast.error("Username hoặc Mật khẩu không đúng!");
        }
    }

    return (
        <div className={"login-screen"}>
            <div className={"login-form"}>
                <h1>Login</h1>
                <Grid container spacing={3}>
                    <Grid xs={12}>
                        <FormControl>
                            <FormLabel>User Name</FormLabel>
                            <Input onChange={e => setUname(e.target.value)}/>
                        </FormControl>
                    </Grid>
                    <Grid xs={12}>
                        <FormControl>
                            <FormLabel>Password</FormLabel>
                            <Input type={"password"} onChange={e => setPassword(e.target.value)}/>
                        </FormControl>
                    </Grid>
                    <Grid xs={12}>
                        <FormControl>
                            <Button onClick={handleLogin}>Đăng nhập</Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}