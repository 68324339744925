class DateUtil {
    static getWeekRange(date) {
        // Clone the input date to avoid mutating it
        const clonedDate = new Date(date);

        // Get the day of the week (0 = Sunday, 6 = Saturday)
        const dayOfWeek = clonedDate.getDay();

        // Calculate the number of days until the next Saturday
        const daysUntilSaturday = 6 - dayOfWeek;

        // Calculate the number of days until the previous Sunday
        const daysUntilSunday = dayOfWeek === 0 ? 0 : dayOfWeek - 1;

        // Clone the input date again to avoid mutating it
        const beginningOfWeek = new Date(clonedDate);
        beginningOfWeek.setDate(clonedDate.getDate() - daysUntilSunday);

        // Clone the input date one more time to avoid mutating it
        const weekend = new Date(clonedDate);
        weekend.setDate(clonedDate.getDate() + daysUntilSaturday);

        // Return an object with both dates
        return {
            weekend,
            beginningOfWeek
        };
    }

    static getMonthRange(date) {
        // Clone the input date to avoid mutating it
        const clonedDate = new Date(date);

        // Get the year and month of the input date
        const year = clonedDate.getFullYear();
        const month = clonedDate.getMonth();

        // Create a new date object for the beginning of the month
        const beginningOfMonth = new Date(year, month, 1);

        // Create a new date object for the end of the month
        const endOfMonth = new Date(year, month + 1, 0);

        // Return an object with both dates
        return {
            beginningOfMonth,
            endOfMonth
        };
    }

    static formatDate(date) {
        let hours = date.getHours();
        hours = hours <= 9 ? '0' + hours : hours;

        let minutes = date.getMinutes();
        minutes = minutes <= 9 ? '0' + minutes : minutes;

        let dateF = date.getDate();
        dateF = dateF <= 9 ? '0' + dateF : dateF;

        let month = date.getMonth() + 1;
        month = month <= 9 ? '0' + month : month;

        return `${hours}:${minutes} ${dateF}/${month}/${date.getFullYear()}`;
    }
}

export default DateUtil;