import {DataTable, TotalCard, View} from "@components";
import {useEffect, useState} from "react";
import {ServiceAPI, SiteAPI} from "@api";
import {toast} from "react-toastify";
import { Autocomplete, Button, Divider, Input, Modal, ModalClose, ModalDialog, Option, Select, Stack, Typography } from "@mui/joy";
import { DateUtil } from "@utils";
import { SiteCategories } from "variables";
import * as Icons from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export const HomeScreen = () => {
    const [sites, setSites] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [filter, setFilter] = useState({
       size: 25,
       page: 0
    });
    const [reports, setReports] = useState({
        categoryTotal: 0,
        todayTotal: 0,
        weekTotal: 0,
        monthTotal: 0
    });
    const [serviceStatus, setServiceStatus] = useState({});
    const [pageSize, setPageSize] = useState(1);
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
    const [duckSearchKeywords, setDuckSearchKeywords] = useState("");
    const [captchaBalance, setCaptchaBalance] = useState(0);
    const [searchLimit, setSearchLimit] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        let interval = setInterval(() => {
            fetchServiceStatus();
        }, 3000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        fetchServiceStatus();
        fetchData();
        fetchReport();
        fetchCaptchaBalance();
    }, [filter]);

    const fetchCaptchaBalance = async () => {
        let balance= await ServiceAPI.get2CaptchaBalance();
        setCaptchaBalance(balance.data?.balance || 0);
    }

    const fetchServiceStatus = async () => {
        let status = await ServiceAPI.getStatus();

        setServiceStatus(status.data);
    }

    const fetchData = async () => {
        let res = await SiteAPI.getAll(filter);

        try {
            setSites(res.data.sites);
            setDataCount(res.data.total);
            setPageSize(res.data.pageSize || 1);
            setReports(reports => ({
                ...reports,
                categoryTotal: res.data.total
            }));
        }catch(e) {
            toast.error("Không lấy được dữ liệu!");
        }
    }

    const fetchReport = async () => {
        try {
            let dateNow = new Date().toISOString().split("T")[0];
            let weekRange = DateUtil.getWeekRange(dateNow);
            let monthRange = DateUtil.getMonthRange(dateNow);
            let excludeCategory = ["uncategorized"];
            let filterData = {...filter};

            delete filterData.domain;

            let dateReport = await SiteAPI.getAll({
                ...filterData,
                dateModifiedFrom: dateNow,
                dateModifiedTo: dateNow,
                excludeCategory,
                report: 1
            });

            let weekReport = await SiteAPI.getAll({
                ...filterData,
                dateModifiedFrom: weekRange.beginningOfWeek,
                dateModifiedTo: weekRange.weekend,
                excludeCategory,
                report: 1
            });

            let monthReport = await SiteAPI.getAll({
                ...filterData,
                dateModifiedFrom: monthRange.beginningOfMonth,
                dateModifiedTo: monthRange.endOfMonth,
                excludeCategory,
                report: 1
            });

            let statistics = await SiteAPI.getStatistics({});

            setReports(reports => ({
                ...reports,
                todayTotal: dateReport.data.total,
                weekTotal: weekReport.data.total,
                monthTotal: monthReport.data.total,
                uncategorized: statistics.data.uncategorized,
                classified: statistics.data.classified,
                categories: statistics.data.categories
            }));
        }catch(e) {
            toast.error("Không lấy được báo cáo!");
        }
    }

    const handleFilter = filterVal => {
        setFilter(filter => ({...filter, ...filterVal}));
    }

    const handleSiteUpdate = async (data) => {
        try {
            await SiteAPI.updateOne(data);
        }catch(e) {
            toast.error("Update dữ liệu không thành công!");
        }
    }

    const handleStartClassify = async() => {
        try {
            setServiceStatus(status => ({...status, isRunning: true}));
            await ServiceAPI.startClassify(filter);

            toast("Đã khởi chạy tiến trình lọc!");
        }catch(e) {
            toast.error("Không chạy được tiến trình!");
        }
    }

    const handleStopClassify = async () => {
        try {
            setServiceStatus(status => ({...status, isRunning: false}));
            await ServiceAPI.stopClassify();

            toast("Đã dừng tiến trình!");
        }catch(e) {
            toast.error("Không dừng được tiến trình!");
        }
    }

    const handleDuckSearch = () => {
        let keywords = duckSearchKeywords.replaceAll(/ /g, "%20");
        navigate("/search?keywords=" + keywords + "&limit=" + searchLimit);
    }

    return (
        <View
            headerbar={{
                lead: [
                    <Input
                        size={"md"}
                        startDecorator={<Icons.IoSearch/>}
                        placeholder="Tìm kiếm từ khoá"
                        onChange={(e) => setDuckSearchKeywords(e.target.value)}
                        endDecorator={
                            <Button onClick={handleDuckSearch}>Tìm</Button>
                        }
                    />,
                    <Input 
                        size={"md"} 
                        placeholder="Giới hạn" 
                        sx={{width: 96}}
                        onChange={e => setSearchLimit(e.target.value)}
                    />
                ],
                action: [
                    <Typography color="primary" fontSize="md" mr={1} startDecorator={<Icons.IoWallet/>}>
                        2Captcha: ${captchaBalance}
                    </Typography>,
                    <Button 
                        size="sm"
                        startDecorator={<Icons.IoCloudUpload/>}
                        onClick={() => setFileUploadModalOpen(true)}
                    >Upload file phân loại</Button>,
                    <Button 
                        size="sm"
                        startDecorator={serviceStatus?.isRunning ? <Icons.IoStop/> : <Icons.IoPlay/>}
                        onClick={serviceStatus?.isRunning ? handleStopClassify : handleStartClassify}
                    >
                        { serviceStatus?.isRunning ? "Dừng phân loại" : "Chạy phân loại" }
                    </Button>
                ]
            }}
        >
            <FileUploadModel
                open={fileUploadModalOpen}
                onClose={() => setFileUploadModalOpen(false)}
            />

            <div className={"home-view"}>
                <Typography level="h3" mt={3} mb={3} fontWeight={600}>Báo cáo thống kê</Typography>
                <Stack direction="row" spacing={2} mb={2}>
                    <TotalCard
                        title="Thống Kê Danh Mục"
                        content={[{
                            title: "Tổng trang thuộc danh mục: ",
                            value: reports.categoryTotal
                        }, {
                            title: "Tổng trang đã phân loại trên DB: ",
                            value: reports.classified
                        }, {
                            title: "Tổng trang chưa được phân loại trên DB: ",
                            value: reports.uncategorized
                        }]}
                    />
                    <TotalCard
                        title="Thống Kê Theo Ngày"
                        content={[{
                            title: "Tổng đã phân loại hôm nay: ",
                            value: reports.todayTotal
                        }, {
                            title: "Tổng đã phân loại tuần này: ",
                            value: reports.weekTotal
                        }, {
                            title: "Tổng đã phân loại tháng này: ",
                            value: reports.monthTotal
                        }]}
                    />
                    <TotalCard
                        title="Tiến Trình Phân Loại"
                        content={[, {
                            title: "Tiến trình đang chạy: ",
                            value: serviceStatus.domain
                        }, {
                            title: "Phân loại thành công: ",
                            value: serviceStatus.success
                        }, {
                            title: "Phân loại thất bại: ",
                            value: serviceStatus.fail
                        }]}
                    />
                </Stack>

                <TotalCard
                    direction="row"
                    title="Thống Kê Theo Phân Loại"
                    content={Object.keys(SiteCategories).map((key) => ({
                        title: SiteCategories[key],
                        value: reports.categories?.[key] || 0
                    }))}
                />

                <Typography level="h3" mt={6} mb={3} fontWeight={600}>Bảng dữ liệu</Typography>
                <DataTable 
                    data={sites} 
                    pageSize={pageSize} 
                    total={dataCount} 
                    onChange={handleSiteUpdate} 
                    onFilter={handleFilter}
                />
            </div>
        </View>
    )
}

const AutoCompleteSiteCategories = Object.keys(SiteCategories).map(key => ({
    label: SiteCategories[key],
    value: key
}));

const FileUploadModel = ({
    open=false,
    onClose=() => {}
}) => {
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState("uncategorized");
    const [threatScore, setThreatScore] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(100);

    const handleUpload = async () => {
        try {
            await SiteAPI.uploadSites(file, category, threatScore, progress => {
                setUploadProgress(progress);
            });

            toast("Upload backup thành công!");

            onClose();
        }catch(e) {
            toast.error("Không thể upload bản backup!");
        }

        setCategory("uncategorized");
        setThreatScore(null);
        setUploadProgress(100);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalDialog>
                <ModalClose />
                <Typography>Upload file</Typography>
                <Stack mt={2} spacing={2}>
                    <Select
                        placeholder="Category"
                        onChange={(e, value) => setCategory(value)}
                    >
                        {
                            Object.keys(SiteCategories).map((key, index) => {
                                let val = SiteCategories[key];
                                return <Option value={key}>{val}</Option>
                            })
                        }
                    </Select>
                    <Input
                        onChange={e => setThreatScore(e.target.value)}
                        defaultValue={threatScore}
                        placeholder="Threat Score"
                        type="number"
                    />
                    <Button
                        variant="soft" 
                        component="label"
                        size="sm"
                        startDecorator={<Icons.IoCloudUpload/>}
                    >
                        {
                            file ? file.name : "Chọn file"
                        }
                        <input hidden accept="*" multiple type="file" onChange={e => setFile(e.target.files[0])} />
                    </Button>
                    <Button
                        variant="solid" 
                        size="md"
                        onClick={handleUpload}
                    >
                        {
                            uploadProgress < 100 ?
                            `Đang tải lên (${uploadProgress} / 100)` : `Upload`
                        }
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    )
}

export default HomeScreen;