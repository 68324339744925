import Axios from "axios";
import Config from "@config/Config";

class ServiceAPI {
    static getStatus() {
        return Axios.get(`${Config.API_CONTEXT}/services/status`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static startClassify({
        domain="",
        threatScoreFrom=0,
        threatScoreTo=100,
        dateModifiedFrom,
        dateModifiedTo,
        domains
    }) {
        let params = {
            domain, threatScoreFrom, threatScoreTo, dateModifiedFrom, dateModifiedTo, domains
        };

        if(!dateModifiedFrom) delete params.dateModifiedFrom;
        if(!dateModifiedTo) delete params.dateModifiedTo;
        if(!domain) delete params.domain;
        if(!domains) delete params.domains;

        if(dateModifiedFrom) {
            let date = new Date(dateModifiedFrom);
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            dateModifiedFrom = date;
        }

        if(dateModifiedTo) {
            let date = new Date(dateModifiedTo);
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            dateModifiedTo = date;
        }

        params = new URLSearchParams(params);

        return Axios.get(`${Config.API_CONTEXT}/services/classify?${params}`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static stopClassify() {
        return Axios.get(`${Config.API_CONTEXT}/services/classify/stop`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static get2CaptchaBalance() {
        return Axios.get(`${Config.API_CONTEXT}/2captcha/balance`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }
}

export default ServiceAPI;
