import Config from "@config/Config";
import Axios from "axios";

class PreferencesAPI {
    static getConfig() {
        return Axios.get(`${Config.API_CONTEXT}/preferences/config`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static updateConfig({
        classifyServiceCron
    }) {
        let data = {
            classifyServiceCron
        };

        Object.keys(data).forEach(key => {
            if(!data[key]) delete data[key]
        });

        return Axios.post(`${Config.API_CONTEXT}/preferences/config`, data, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }
}

export default PreferencesAPI;