import {DataTable, SearchResultCard, TotalCard, View} from "@components";
import {
    Button,
    IconButton,
    Input,
    ListItemDecorator,
    Stack,
    Tab,
    tabClasses,
    TabList,
    TabPanel,
    Tabs,
    Typography
} from "@mui/joy";
import * as Icons from "react-icons/io5";
import {useEffect, useState} from "react";
import {SearchAPI, ServiceAPI, SiteAPI} from "@api";
import {toast} from "react-toastify";

export const UncategorizedScreen = () => {
    return (
        <View>
            <Tabs aria-label="Basic tabs" defaultValue={0} sx={{ borderRadius: 'lg' }}>
                <TabList
                    variant="plain"
                    sx={{
                        '--List-padding': '0px',
                        '--List-radius': '0px',
                        '--ListItem-minHeight': '48px',
                        [`& .${tabClasses.root}`]: {
                            boxShadow: 'none',
                            fontWeight: 'md',
                            [`&.${tabClasses.selected}::before`]: {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                left: 'var(--ListItem-paddingLeft)', // change to `0` to stretch to the edge.
                                right: 'var(--ListItem-paddingRight)', // change to `0` to stretch to the edge.
                                bottom: 0,
                                height: 3,
                                bgcolor: 'primary.400',
                            },
                        },
                    }}
                >
                    <Tab>
                        <ListItemDecorator>
                            <Icons.IoSearch/>
                        </ListItemDecorator>
                        Tìm kiếm
                    </Tab>
                    <Tab>
                        <ListItemDecorator>
                            <Icons.IoArchiveOutline/>
                        </ListItemDecorator>
                        Chưa chạy phân loại
                    </Tab>
                    <Tab>
                        <ListItemDecorator>
                            <Icons.IoArchiveOutline/>
                        </ListItemDecorator>
                        Đã phân loại - Uncategorized
                    </Tab>
                    <Tab>
                        <ListItemDecorator>
                            <Icons.IoCellularOutline/>
                        </ListItemDecorator>
                        Kết quả phân loại
                    </Tab>
                </TabList>
                <TabPanel value={0} sx={{ p: 2 }}>
                    <SearchView/>
                </TabPanel>
                <TabPanel value={1} sx={{ p: 2 }}>
                    <UncategorizedView/>
                </TabPanel>
                <TabPanel value={2} sx={{ p: 2 }}>
                    <UncategorizedView classified/>
                </TabPanel>
                <TabPanel value={3} sx={{ p: 2 }}>
                    <ResultView/>
                </TabPanel>
            </Tabs>
        </View>
    )
}

const SearchView = () => {
    const [keywords, setKeywords] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [limit, setLimit] = useState("");
    const [serviceStatus, setServiceStatus] = useState({});
    const [captchaBalance, setCaptchaBalance] = useState(0);

    useEffect(() => {
        let interval = setInterval(() => {
            fetchServiceStatus();
        }, 3000);

        fetchCaptchaBalance();

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        let params = window.location.hash.split("keywords=")?.[1]?.split("&limit=");

        if(params) {
            let keywords = decodeURI(params[0]);
            let limit = params[1];

            if(limit != 0) {
                setLimit(limit);
            }

            if(keywords) {
                setKeywords(keywords.replaceAll(/%20/g, " "));
                handleSearch(keywords);
            }
        }
    }, []);

    const fetchCaptchaBalance = async () => {
        let balance= await ServiceAPI.get2CaptchaBalance();
        setCaptchaBalance(balance.data?.balance || 0);
    }

    const fetchServiceStatus = async () => {
        let status = await ServiceAPI.getStatus();

        setServiceStatus(status.data);
    }

    const handleSearch = async (keyw, flimit) => {
        setLoading(true);

        try {
            let searchKeyw = keywords || keyw;
            let searchLimit = limit || flimit;

            let results = await SearchAPI.search(searchKeyw, searchLimit);
            results = results.data;

            setResults(results);

            toast("Tìm kiếm thành công, đã lưu dữ liệu vào DB!");
        }catch(e) {
            console.log(e);
            toast.error("Không tìm kiếm thành công!");
        }

        setLoading(false);
    }

    const handleStartClassify = async() => {
        try {
            let domains = results.map(item => item.domain);

            setServiceStatus(status => ({...status, isRunning: true}));
            await ServiceAPI.startClassify({
                domains
            });

            toast("Đã khởi chạy tiến trình lọc!");
        }catch(e) {
            toast.error("Không chạy được tiến trình!");
        }
    }

    const handleStopClassify = async () => {
        try {
            setServiceStatus(status => ({...status, isRunning: false}));
            // await ServiceAPI.stopClassify();

            toast("Đã dừng tiến trình!");
        }catch(e) {
            toast.error("Không dừng được tiến trình!");
        }
    }

    return (
        <div>
            <Typography level="h3" mt={3} mb={3} fontWeight={600}>Tìm kiếm từ khoá</Typography>
            <Stack direction={"row"} spacing={1}>
                <Input
                    size={"md"}
                    startDecorator={<Icons.IoSearch/>}
                    placeholder="Tìm kiếm từ khoá"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    endDecorator={
                        <Button loading={loading} onClick={handleSearch}>Tìm</Button>
                    }
                />
                <Input
                    size={"md"}
                    placeholder="Giới hạn"
                    sx={{width: 96}}
                    onChange={e => setLimit(e.target.value)}
                    value={limit}
                />
                <div style={{flex: 1}}></div>
                <Typography color="primary" fontSize="md" mr={1} startDecorator={<Icons.IoWallet/>}>
                    2Captcha: ${captchaBalance}
                </Typography>
                <Button
                    size="sm"
                    startDecorator={serviceStatus?.isRunning ? <Icons.IoStop/> : <Icons.IoPlay/>}
                    onClick={serviceStatus?.isRunning ? handleStopClassify : handleStartClassify}
                >
                    { serviceStatus?.isRunning ? "Dừng phân loại" : "Chạy phân loại" }
                </Button>
            </Stack>
            <Typography level="body2" mt={3} mb={3} fontWeight={600}>Đã tìm được { results.length } kết quả</Typography>
            <Stack spacing={2}>
                {
                    results.map((item, index) => {
                        return <SearchResultCard
                            key={`search-result-${index}`}
                            title={item.title}
                            icon={item.icon}
                            domain={item.domain}
                            desc={item.description}
                        />
                    })
                }
            </Stack>
        </div>
    )
}

const UncategorizedView = ({
    classified
}) => {
    const [sites, setSites] = useState([]);
    const [pageSize, setPageSize] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [filter, setFilter] = useState({
        size: 25,
        page: 0,
        category: "uncategorized",
        threatScoreFrom: classified ? 1 : 0,
        threatScoreTo: classified ? 100 : 0
    });
    const [serviceStatus, setServiceStatus] = useState({});
    const [captchaBalance, setCaptchaBalance] = useState(0);

    useEffect(() => {
        fetchData();
        fetchCaptchaBalance();
        fetchServiceStatus();

        let interval = setInterval(() => {
            fetchServiceStatus();
        }, 3000);

        return () => {
            clearInterval(interval);
        }
    }, [filter]);

    const fetchCaptchaBalance = async () => {
        let balance= await ServiceAPI.get2CaptchaBalance();
        setCaptchaBalance(balance.data?.balance || 0);
    }

    const fetchServiceStatus = async () => {
        let status = await ServiceAPI.getStatus();

        setServiceStatus(status.data);
    }

    const fetchData = async () => {
        let res = await SiteAPI.getAll(filter);

        try {
            setSites(res.data.sites);
            setDataCount(res.data.total);
            setPageSize(res.data.pageSize || 1);
        }catch(e) {
            toast.error("Không lấy được dữ liệu!");
        }
    }

    const handleFilter = filterVal => {
        setFilter(filter => ({...filter, ...filterVal}));
    }

    const handleSiteUpdate = async (data) => {
        try {
            await SiteAPI.updateOne(data);
        }catch(e) {
            toast.error("Update dữ liệu không thành công!");
        }
    }

    const handleStartClassify = async() => {
        try {
            setServiceStatus(status => ({...status, isRunning: true}));
            await ServiceAPI.startClassify(filter);

            toast("Đã khởi chạy tiến trình lọc!");
        }catch(e) {
            toast.error("Không chạy được tiến trình!");
        }
    }

    const handleStopClassify = async () => {
        try {
            setServiceStatus(status => ({...status, isRunning: false}));
            await ServiceAPI.stopClassify();

            toast("Đã dừng tiến trình!");
        }catch(e) {
            toast.error("Không dừng được tiến trình!");
        }
    }

    return (
        <div>
            <Typography level="h3" mt={3} mb={3} fontWeight={600}>Báo cáo thống kê</Typography>
            <Stack direction="row" spacing={2} mb={2}>
                <TotalCard
                    title="Thống Kê Danh Mục"
                    content={[{
                        title: "Tổng trang chưa được phân loại trên DB: ",
                        value: dataCount
                    }]}
                />
                <TotalCard
                    title="Tiến Trình Phân Loại"
                    content={[, {
                        title: "Tiến trình đang chạy: ",
                        value: serviceStatus.domain
                    }, {
                        title: "Phân loại thành công: ",
                        value: serviceStatus.success
                    }, {
                        title: "Phân loại thất bại: ",
                        value: serviceStatus.fail
                    }]}
                />
            </Stack>

            <Stack direction={"row"} mt={6} mb={3} spacing={{xs: 1}}>
                <Typography level="h3" fontWeight={600}>Bảng dữ liệu</Typography>
                <div style={{flex: 1}}></div>
                <Typography color="primary" fontSize="md" mr={1} startDecorator={<Icons.IoWallet/>}>
                    2Captcha: ${captchaBalance}
                </Typography>
                <Button
                    size="sm"
                    startDecorator={serviceStatus?.isRunning ? <Icons.IoStop/> : <Icons.IoPlay/>}
                    onClick={serviceStatus?.isRunning ? handleStopClassify : handleStartClassify}
                >
                    { serviceStatus?.isRunning ? "Dừng phân loại" : "Chạy phân loại" }
                </Button>
            </Stack>
            <DataTable
                data={sites}
                pageSize={pageSize}
                total={dataCount}
                onChange={handleSiteUpdate}
                onFilter={handleFilter}
                noCategory={true}
            />
        </div>
    )
}

const ResultView = () => {
    const [sites, setSites] = useState([]);
    const [pageSize, setPageSize] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [filter, setFilter] = useState({
        size: 25,
        page: 0,
        excludeCategory: ["uncategorized"]
    });

    useEffect(() => {
        fetchData();
    }, [filter]);

    const fetchData = async () => {
        let res = await SiteAPI.getAll(filter);

        try {
            setSites(res.data.sites);
            setDataCount(res.data.total);
            setPageSize(res.data.pageSize || 1);
        }catch(e) {
            toast.error("Không lấy được dữ liệu!");
        }
    }

    const handleFilter = filterVal => {
        setFilter(filter => ({...filter, ...filterVal}));
    }

    const handleSiteUpdate = async (data) => {
        try {
            await SiteAPI.updateOne(data);
        }catch(e) {
            toast.error("Update dữ liệu không thành công!");
        }
    }

    return (
        <div>
            <Typography level="h3" mt={3} mb={3} fontWeight={600}>Tiến trình đã phân loại</Typography>
            <DataTable
                data={sites}
                pageSize={pageSize}
                total={dataCount}
                onChange={handleSiteUpdate}
                onFilter={handleFilter}
            />
        </div>
    )
}
