import "./styles.scss";
import * as Icons from "react-icons/io5";

export const Sidebar = () => {
    const pathname = window.location.hash;

    return (
        <div className={"sidebar"}>
            <span className={"sidebar-group-title"}>Dashboard</span>
            <a className={`sidebar-item ${pathname == "" || pathname=="#/" ? "active" : ""}`} href="/#/">
                <span className={"icon"}>
                    <Icons.IoBarChartOutline/>
                </span>
                <span className={"title"}>Dashboard</span>
            </a>
            <a className={`sidebar-item ${pathname == "#/uncategorized" ? "active" : ""}`} href="/#/uncategorized">
                <span className={"icon"}>
                    <Icons.IoBarChartOutline/>
                </span>
                <span className={"title"}>Tạo dữ liệu</span>
            </a>
            <a className={`sidebar-item ${pathname == "#/backup" ? "active" : ""}`} href="/#/backup">
                <span className={"icon"}>
                    <Icons.IoGitBranchOutline/>
                </span>
                <span className={"title"}>Backup</span>
            </a>
            <span className={"sidebar-group-title"}>Another</span>
            <a className={`sidebar-item ${pathname == "#/settings" ? "active" : ""}`} href="/#/settings">
                <span className={"icon"}>
                    <Icons.IoSettingsOutline/>
                </span>
                <span className={"title"}>Cài đặt</span>
            </a>
            <div className="sidebar-spacing"></div>
            <a className={`sidebar-item ${pathname == "#/logout" ? "active" : ""}`} href="/#/logout">
                <span className={"icon"}>
                    <Icons.IoLogOutOutline/>
                </span>
                <span className={"title"}>Logout</span>
            </a>
        </div>
    )
}
