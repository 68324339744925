import {SearchAPI, ServiceAPI} from "@api";
import { SearchResultCard, View } from "@components"
import { Button, IconButton, Input, Stack, Typography } from "@mui/joy"
import { useEffect, useState } from "react";
import * as Icons from "react-icons/io5";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const SearchScreen = () => {
    const [keywords, setKeywords] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [limit, setLimit] = useState("");
    const [serviceStatus, setServiceStatus] = useState({});

    useEffect(() => {
        let interval = setInterval(() => {
            fetchServiceStatus();
        }, 3000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        let params = window.location.hash.split("keywords=")?.[1]?.split("&limit=");
        let keywords = decodeURI(params[0]);
        let limit = params[1];

        if(limit != 0) {
            setLimit(limit);
        }

        if(keywords) {
            setKeywords(keywords.replaceAll(/%20/g, " "));
            handleSearch(keywords);
        }
    }, []);

    const fetchServiceStatus = async () => {
        let status = await ServiceAPI.getStatus();

        setServiceStatus(status.data);
    }

    const handleSearch = async (keyw, flimit) => {
        setLoading(true);

        try {
            let searchKeyw = keywords || keyw;
            let searchLimit = limit || flimit;

            let results = await SearchAPI.search(searchKeyw, searchLimit);
            results = results.data;

            setResults(results);

            toast("Tìm kiếm thành công, đã lưu dữ liệu vào DB!");
        }catch(e) {
            console.log(e);
            toast.error("Không tìm kiếm thành công!");
        }

        setLoading(false);
    }

    const handleStartClassify = async() => {
        try {
            let domains = results.map(item => item.domain);

            setServiceStatus(status => ({...status, isRunning: true}));
            await ServiceAPI.startClassify({
                domains
            });

            toast("Đã khởi chạy tiến trình lọc!");
        }catch(e) {
            toast.error("Không chạy được tiến trình!");
        }
    }

    const handleStopClassify = async () => {
        try {
            setServiceStatus(status => ({...status, isRunning: false}));
            // await ServiceAPI.stopClassify();

            toast("Đã dừng tiến trình!");
        }catch(e) {
            toast.error("Không dừng được tiến trình!");
        }
    }

    return (
        <View
            headerbar={{
                lead: [
                    <IconButton component={"a"} href="/#/" variant="plain">
                        <Icons.IoArrowBack/>
                    </IconButton>,
                    <Input
                        size={"md"}
                        startDecorator={<Icons.IoSearch/>}
                        placeholder="Tìm kiếm từ khoá"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        endDecorator={
                            <Button loading={loading} onClick={handleSearch}>Tìm</Button>
                        }
                    />,
                    <Input
                        size={"md"}
                        placeholder="Giới hạn"
                        sx={{width: 96}}
                        onChange={e => setLimit(e.target.value)}
                        value={limit}
                    />
                ],
                action: [
                    <Button
                        size="sm"
                        startDecorator={serviceStatus?.isRunning ? <Icons.IoStop/> : <Icons.IoPlay/>}
                        onClick={serviceStatus?.isRunning ? handleStopClassify : handleStartClassify}
                    >
                        { serviceStatus?.isRunning ? "Dừng phân loại" : "Chạy phân loại" }
                    </Button>
                ]
            }}
        >
            <Typography level="body2" mt={0.5} mb={3} fontWeight={600}>Đã tìm được { results.length } kết quả</Typography>
            <Stack spacing={2}>
                {
                    results.map((item, index) => {
                        return <SearchResultCard
                            key={`search-result-${index}`}
                            title={item.title}
                            icon={item.icon}
                            domain={item.domain}
                            desc={item.description}
                        />
                    })
                }
            </Stack>
        </View>
    )
}
