import {
    Button, FormControl,
    FormLabel,
    Grid,
    Input,
    Modal,
    ModalClose,
    ModalDialog,
    Select,
    Stack,
    Table,
    Typography,
} from "@mui/joy";
import Autocomplete from '@mui/joy/Autocomplete';
import Option from '@mui/joy/Option';
import * as Icons from "react-icons/io5";
import Pagination from '@mui/material/Pagination'

import "./styles.scss";
import {SiteCategories} from "../../variables";
import {useState, useCallback} from "react";
import { DateUtil } from "@utils";

const AutoCompleteSiteCategories = Object.keys(SiteCategories).map(key => ({
    label: SiteCategories[key],
    value: key
}));

export const DataTable = ({ data, total=0, noCategory, page=1, pageSize=1, defaultPage=1, onChange=()=>{}, onFilter }) => {
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [webViewModalOpen, setWebViewModelOpen] = useState(false);
    const [webViewUrl, setWebViewUrl] = useState("https://google.com");
    const [filterValue, setFilterValue] = useState({});
    const [pageIndex, setPageIndex] = useState(defaultPage);
    let dateNow = new Date().toISOString().split("T")[0];

    const handleFilter = (filter) => {
        let filterData = {
            ...filterValue,
            ...filter
        }

        setFilterValue(filterData);
        onFilter(filterData);
    }

    const debounce = (func, timeout = 300) => {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const debounceDomain = useCallback(debounce((_id, value) => onChange({
        _id,
        domain: value
    }), 1500), []);

    const debounceThreatScore = useCallback(debounce((_id, value) => onChange({
        _id,
        threatScore: Number(value)
    }), 1500), []);

    const debounceSearch = useCallback(debounce((value) => handleFilter({
        domain: value
    }), 1000), []);

    return (
        <div className={"data-table"}>
            {/* <FilterModal
                onFilter={handleFilter}
                onClose={() => setFilterModalOpen(false)}
                open={filterModalOpen}
            /> */}
            <WebViewModel
                url={webViewUrl}
                open={webViewModalOpen}
                onClose={() => setWebViewModelOpen(false)}
            />
            <div className={"data-table-head-control"}>
                <div className={"data-table-head-control-lead"}>
                    <Select size="sm" defaultValue={"25"}>
                        <Option value="25" onClick={() => handleFilter({
                            size: 25
                        })}>25</Option>
                        <Option value="50" onClick={() => handleFilter({
                            size: 50
                        })}>50</Option>
                        <Option value="75" onClick={() => handleFilter({
                            size: 75
                        })}>75</Option>
                        <Option value="100" onClick={() => handleFilter({
                            size: 100
                        })}>100</Option>
                        <Option value="" onClick={() => handleFilter({
                            size: 0
                        })}>All</Option>
                    </Select>
                    <Select size="sm" defaultValue={"all"}>
                        <Option value="all" onClick={() => handleFilter({
                            dateModifiedFrom: null,
                            dateModifiedTo: null,
                        })}>Tất cả</Option>
                        <Option value="today" onClick={() => handleFilter({
                            dateModifiedFrom: dateNow,
                            dateModifiedTo: dateNow,
                        })}>Hôm nay</Option>
                        <Option value="week" onClick={() => handleFilter({
                            dateModifiedFrom: DateUtil.getWeekRange(dateNow).beginningOfWeek,
                            dateModifiedTo: DateUtil.getWeekRange(dateNow).weekend,
                        })}>Tuần này</Option>
                        <Option value="month" onClick={() => handleFilter({
                            dateModifiedFrom: DateUtil.getMonthRange(dateNow).beginningOfMonth,
                            dateModifiedTo: DateUtil.getMonthRange(dateNow).endOfMonth,
                        })}>Tháng này</Option>
                    </Select>
                    { !noCategory && <Select size="sm" defaultValue={"all"}>
                        <Option value="all" onClick={() => handleFilter({
                            category: ""
                        })}>Tất cả</Option>
                        {
                            Object.keys(SiteCategories).map((key, index) => {
                                let val = SiteCategories[key];
                                return <Option
                                    key={`table-data-filter-category-option-${index}`}
                                    value={key}
                                    onClick={() => handleFilter({
                                        category: key
                                    })}
                                >{val}</Option>
                            })
                        }
                    </Select> }
                    {/* <Button size="sm" variant={"outlined"} onClick={() => setFilterModalOpen(true)} color="neutral">
                        <Icons.IoFilter/>
                    </Button> */}
                    <Input
                        placeholder="Tìm kiếm"
                        size="sm"
                        onChange={e => debounceSearch(e.target.value)}
                    />
                </div>
                <div className={"data-table-head-control-action"}>
                    <Pagination
                        count={pageSize}
                        onChange={(e, value) => {
                            handleFilter({
                                page: value - 1
                            });
                            setPageIndex(value);
                        }}
                        shape="rounded"
                        page={pageIndex}
                    />
                </div>
            </div>

            <Table>
                <thead>
                <tr>
                    <th style={{width: 48}}>STT</th>
                    <th>Domain</th>
                    <th>Category</th>
                    <th style={{width: 96}}>Threat Score</th>
                    {/* <th>Description</th> */}
                    <th style={{width: 128}}>Date Modified</th>
                </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => {
                          return (
                                <tr key={`data-table-row-${index}`}>
                                    <td>{ index + 1 }</td>
                                    <td>
                                        <Stack direction="row">
                                            <Button 
                                                size="sm" 
                                                variant="soft" 
                                                color="neutral"
                                                onClick={() => {
                                                    window.open(`http://${item.domain}`, item.domain, "toolbar=no,menubar=no");
                                                }}
                                            >
                                                <Icons.IoEye/>
                                            </Button>
                                            <Input
                                                key={`domain-input-${item._id}`}
                                                placeholder="domain.com"
                                                defaultValue={ item.domain }
                                                variant="plain"
                                                onChange={e => debounceDomain(item._id, e.target.value)}
                                            />
                                        </Stack>
                                    </td>
                                    <td>
                                    <Autocomplete
                                        key={`category-input-${item._id}`}
                                        placeholder="Combo box"
                                        options={AutoCompleteSiteCategories}
                                        defaultValue={SiteCategories[item.category]}
                                        variant="plain"
                                        onChange={e => {
                                            let value = AutoCompleteSiteCategories.find(item => item.label == e.target.innerText);
                                            value = value || {
                                                value: "uncategorized"
                                            }

                                            onChange({
                                                _id: item._id,
                                                category: value.value
                                            });
                                        }}
                                    />
                                    </td>
                                    <td>
                                    <Input
                                        key={`threat-input-${item._id}`}
                                        placeholder="Threat Score"
                                        type={"number"}
                                        defaultValue={ item.threatScore }
                                        variant="plain"
                                        onChange={e => debounceThreatScore(item._id, e.target.value)}
                                    />
                                    </td>
                                    {/* <td style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    }}>{ item.description }</td> */}
                                    <td>{ DateUtil.formatDate(new Date(item.dateModified)) }</td>
                                </tr>
                          )
                        })
                    }
                </tbody>
            </Table>

            <div className="data-table-footer">
                <div className="data-table-footer-lead"></div>
                <div className="data-table-footer-action">
                    <Pagination
                        count={pageSize}
                        onChange={(e, value) => {
                            handleFilter({
                                page: value - 1
                            });
                            setPageIndex(value);
                        }}
                        shape="rounded"
                        page={pageIndex}
                    />
                </div>
            </div>
        </div>
    )
}

const WebViewModel = ({ open, onClose, url }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <ModalClose />
                <Typography>{url}</Typography>
                <iframe src={url} loading="lazy" width={1024} height={768} seamless/>
            </ModalDialog>
        </Modal>
    )
}

const FilterModal = ({ open, onClose, onFilter }) => {
    let dateNow = new Date().toISOString().split("T")[0];

    const [category, setCategory] = useState("");
    const [threatScoreFrom, setThreatScoreFrom] = useState(0);
    const [threatScoreTo, setThreatScoreTo] = useState(100);
    const [dateModifiedFrom, setDateModifiedFrom] = useState(dateNow);
    const [dateModifiedTo, setDateModifiedTo] = useState(dateNow);

    const handleFilter = () => {
        let filter = {
            category,
            threatScoreFrom: Number(threatScoreFrom),
            threatScoreTo: Number(threatScoreTo),
            dateModifiedFrom,
            dateModifiedTo
        }

        onFilter(filter);
        onClose();
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalDialog
                sx={{ maxWidth: 360 }}
            >
                <ModalClose />
                <Typography>Filter</Typography>
                <Stack spacing={3} mt={3}>
                    <FormControl>
                        <FormLabel>Category</FormLabel>
                        <Select defaultValue={category}>
                            <Option value="" onClick={() => setCategory("")}>All</Option>
                            {
                                Object.keys(SiteCategories).map((key, index) => {
                                    let val = SiteCategories[key];
                                    return <Option
                                        key={`table-data-filter-category-option-${index}`}
                                        value={key}
                                        onClick={() => setCategory(key)}
                                    >{val}</Option>
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Score</FormLabel>

                        <div style={{
                            display: "flex",
                            gap: 6
                        }}>
                            <Input style={{
                                flex: 1
                            }} placeholder={"From"} onChange={e => setThreatScoreFrom(e.target.value)} defaultValue={threatScoreFrom} type={"number"}/>
                            <Input style={{
                                flex: 1
                            }} placeholder={"To"} onChange={e => setThreatScoreTo(e.target.value)} defaultValue={threatScoreTo} type={"number"}/>
                        </div>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Date Modified</FormLabel>

                        <div style={{
                            display: "flex",
                            gap: 6
                        }}>
                            <Input style={{
                                flex: 1
                            }} placeholder={"From"} onChange={e => setDateModifiedFrom(e.target.value)} defaultValue={dateModifiedFrom} type={"date"}/>
                            <Input style={{
                                flex: 1,
                            }} placeholder={"To"} onChange={e => setDateModifiedTo(e.target.value)} defaultValue={dateModifiedTo} type={"date"}/>
                        </div>
                    </FormControl>
                    <Button onClick={handleFilter}>Lọc</Button>
                </Stack>
            </ModalDialog>
        </Modal>
    )
}
