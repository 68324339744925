import Axios from "axios";
import Config from "@config/Config";

class SiteAPI {
    static async getAll({
        page=0,
        size=25,
        domain="",
        category="",
        threatScoreFrom=0,
        threatScoreTo=100,
        dateModifiedFrom = "",
        dateModifiedTo =  "",
        excludeCategory = [],
        report
    }) {
        let params = {
            page, size, domain, category, threatScoreFrom, threatScoreTo, dateModifiedFrom, dateModifiedTo, excludeCategory: excludeCategory.join(","), report
        };

        if(!dateModifiedFrom) delete params.dateModifiedFrom;
        if(!dateModifiedTo) delete params.dateModifiedTo;

        if(dateModifiedFrom) {
            let date = new Date(dateModifiedFrom);
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` 
            dateModifiedFrom = date;
        }

        if(dateModifiedTo) {
            let date = new Date(dateModifiedTo);
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` 
            dateModifiedTo = date;
        }

        if(!params.report) {
            delete params.report;
        }

        params = new URLSearchParams(params);

        return Axios.get(`${Config.API_CONTEXT}/sites?${params}`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            console.log(e);
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static async updateOne({
        _id,
        domain,
        category,
        threatScore
    }) {
        return Axios.post(`${Config.API_CONTEXT}/sites/${_id}`, {
            domain,
            category,
            threatScore
        }, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static async getStatistics({
        dateModifiedFrom,
        dateModifiedTo
    }) {
        let params = {
            dateModifiedFrom, dateModifiedTo
        };

        if(!dateModifiedFrom) delete params.dateModifiedFrom;
        if(!dateModifiedTo) delete params.dateModifiedTo;

        if(dateModifiedFrom) {
            let date = new Date(dateModifiedFrom);
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` 
            dateModifiedFrom = date;
        }

        if(dateModifiedTo) {
            let date = new Date(dateModifiedTo);
            date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` 
            dateModifiedTo = date;
        }

        params = new URLSearchParams(params);

        return Axios.get(`${Config.API_CONTEXT}/sites/statistics?${params}`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static uploadSites(file, category, threatScore, onProgress = e => {}) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("category", category);
        formData.append("threatScore", threatScore);

        return Axios.post(`${Config.API_CONTEXT}/sites/file`, formData, {
            onUploadProgress: progressEvent => onProgress((progressEvent.loaded / progressEvent.total) * 100),
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }
}

export default SiteAPI;