import Config from "@config/Config";
import Axios from "axios";

class BackupAPI {
    static create() {
        return Axios.get(`${Config.API_CONTEXT}/backup/create`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static getAll() {
        return Axios.get(`${Config.API_CONTEXT}/backup`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static upload(file, onProgress = e => {}) {
        const formData = new FormData();
        formData.append("file", file);

        return Axios.post(`${Config.API_CONTEXT}/backup`, formData, {
            onUploadProgress: progressEvent => onProgress((progressEvent.loaded / progressEvent.total) * 100),
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static restore(fileName) {
        let params = new URLSearchParams({ fileName });

        return Axios.get(`${Config.API_CONTEXT}/restore?${params}`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }

    static delete(fileName) {
        return Axios.delete(`${Config.API_CONTEXT}/backup/${fileName}`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }
}

export default BackupAPI;
