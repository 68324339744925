import Config from "@config/Config";
import Axios from "axios";

class SearchAPI {
    static search(keywords, limit) {
        let params = {
            keywords: keywords.replaceAll(/ /g, "%20"),
            limit
        }

        params = new URLSearchParams(params);

        return Axios.get(`${Config.API_CONTEXT}/services/search?${params}`, {
            headers: {
                token: localStorage.getItem("@token")
            }
        }).catch(e => {
            if(e.response.status == 401) {
                window.location.href="/#/logout";
            }else{
                throw e;
            }
        });
    }
}

export default SearchAPI;