import { useEffect } from "react"

export const LogoutScreen = () => {
    useEffect(() => {
        localStorage.clear();

        window.location.href = "/#/login";

        window.location.reload();
    }, []);

    return (
        <div></div>
    )
}