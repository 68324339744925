import {Sidebar, Headerbar} from "@components";
import "./styles.scss";

export const View = ({ children, headerbar={
    action: null,
    lead: null
} }) => {
    return (
        <div className={"view"}>
            <Sidebar/>

            <div className={"child-container"}>
                {
                    headerbar.action || headerbar.lead ?
                        <Headerbar action={headerbar.action} lead={headerbar.lead}/> : null
                }
                <div className={"comp-view-container"}>
                    { children }

                    <div className="spacing"></div>
                </div>
            </div>
        </div>
    )
}
