import Axios from "axios";
import Config from "@config/Config";

class UserAPI {
    static async auth(uname, password) {
        return Axios.post(`${Config.API_CONTEXT}/auth`, {
            uname, password
        })
    }
}

export default UserAPI;