import logo from './logo.svg';
import './App.css';
import {Switch} from "@mui/joy";
import {Route, Routes} from "react-router-dom";

import {BackupScreen, LoginScreen, LogoutScreen, SearchScreen, SettingsScreen, UncategorizedScreen} from "@views";
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import HomeScreen from "@views/Home";


const App = ({}) => {
  return (
      <main>
          <ToastContainer/>
          {/*<h1>HELLO WORLD</h1>*/}
        <Routes>
          <Route path={"/settings"} element={<SettingsScreen/>}/>
            <Route path={"/uncategorized"} element={<UncategorizedScreen/>}/>
            <Route path={"/search"} element={<SearchScreen/>}/>
            <Route path={"/login"} element={<LoginScreen/>}/>
            <Route path={"/logout"} element={<LogoutScreen/>}/>
            <Route path={"/backup"} element={<BackupScreen/>}/>
            <Route path={"/"} element={<HomeScreen/>}/>
        </Routes>
      </main>
  )
}

export default App;
