import { BackupAPI } from "@api";
import { View } from "@components"
import { Button, Stack, Table, Typography } from "@mui/joy"
import Config from "@config/Config";
import { useEffect, useState } from "react";
import * as Icons from "react-icons/io5";
import { toast } from "react-toastify";
import { DateUtil } from "@utils";

export const BackupScreen = () => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(100);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            let files = await BackupAPI.getAll();

            setFiles(files.data);
        }catch(e) {
            toast.error("Không thể lấy file!");
        }
    }

    const handleCreateBackup = async() => {
        try {
            await BackupAPI.create();

            toast("Đã tạo bản lưu thành công!");

            setTimeout(fetchData, 1500);
        }catch(e) {
            toast.error("Không thể tạo bản backup!");
        }
    }

    const handleUploadBackup = async(e) => {
        try {
            let file = e.target.files[0];

            await BackupAPI.upload(file, progress => {
                setUploadProgress(progress);
            });

            toast("Upload backup thành công!");
        }catch(e) {
            toast.error("Không thể upload bản backup!");
        }
    }

    const handleRestore = async fileName => {
        try {
            await BackupAPI.restore(fileName);

            toast("Restore thành công!");
        }catch(e) {
            toast.error("Không thể restore bản backup!");
        }
    }

    const handleDelete = async fileName => {
        try {
            if (window.confirm('Bạn có muốn xoá file này?')) {
                await BackupAPI.delete(fileName);

                toast("Xoá thành công!");

                setTimeout(fetchData, 1500);
            }
        }catch(e) {
            toast.error("Không thể xoá bản backup!");
        }
    }

    return (
        <View
            headerbar={{
                action: [
                    <Button
                        size="sm"
                        style={{
                            height: 32
                        }}
                        startDecorator={<Icons.IoCloudUpload/>}
                        component="label"
                        loading={uploadProgress < 100}
                    >
                        {
                            uploadProgress < 100 ?
                            `Đang tải lên (${uploadProgress} / 100)` : `Tải lên bản Backup`
                        }
                        <input hidden accept="*" multiple type="file" onChange={handleUploadBackup} />
                    </Button>,
                    <Button
                        size="sm"
                        style={{
                            height: 36
                        }}
                        startDecorator={<Icons.IoSave/>}
                        onClick={handleCreateBackup}
                    >Tạo bản Backup</Button>
                ]
            }}
        >
            <Typography level="h3" mt={3} fontWeight={600}>Bảng Backup</Typography>
            <Table>
                <thead>
                    <tr>
                        <th style={{width: 48}}>STT</th>
                        <th>Thời gian</th>
                        <th>Tên File</th>
                        <th style={{width: 256}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        files.map((item, index) => {
                            return (
                                <tr key={`table-row-file-${index}`}>
                                    <td>{ index + 1 }</td>
                                    <td>{ DateUtil.formatDate(new Date(Number(item.split(".")?.[0] || item))) }</td>
                                    <td>{ item }</td>
                                    <td>
                                        <Stack direction={"row"} spacing={.5}>
                                            <Button aria-label="Like" onClick={() => handleRestore(item)} startDecorator={<Icons.IoGitPullRequestOutline/>} size="sm" variant="soft" color="neutral">
                                                Restore
                                            </Button>
                                            <Button component="a" href={`${Config.API_HOST}/backup/${item}`} target="_blank" aria-label="Like" startDecorator={<Icons.IoDownloadOutline/>} size="sm" variant="soft" color="neutral">
                                                Tải xuống
                                            </Button>
                                            <Button onClick={() => handleDelete(item)} target="_blank" aria-label="Like" size="sm" variant="soft" color="neutral">
                                                <Icons.IoTrashOutline/>
                                            </Button>
                                        </Stack>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </View>
    )
}
