import { Card, Typography, Stack, Divider } from "@mui/joy"

export const TotalCard = ({
    title,
    content=[],
    direction="column"
}) => {
    return (
        <Card variant="outlined">
            <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>{ title }</Typography>
            <Stack direction={direction} spacing={0.5} mt={0.5} flexWrap="wrap" divider={<Divider orientation="vertical" />}>
                {
                    content.map((item, index) => {
                        if(direction == "column") {
                            return (
                                <>
                                    <Typography level="body3" >{ item.title }</Typography>
                                    <Typography fontSize="lg" fontWeight="lg">{ item.value }</Typography>
                                </>
                            )
                        }else{
                            if(index % 3 == 0) {
                                return (
                                    <Stack spacing={0.5}>
                                        <>
                                            <Typography level="body3" >{ content[index].title }</Typography>
                                            <Typography fontSize="lg" fontWeight="lg">{ content[index].value }</Typography>
                                        </>
                                        <>
                                            <Typography level="body3" >{ content[index + 1]?.title }</Typography>
                                            <Typography fontSize="lg" fontWeight="lg">{ content[index + 1]?.value }</Typography>
                                        </>
                                        <>
                                            <Typography level="body3" >{ content[index + 2]?.title }</Typography>
                                            <Typography fontSize="lg" fontWeight="lg">{ content[index + 2]?.value }</Typography>
                                        </>
                                    </Stack>
                                )
                            }
                        }
                    })
                }
            </Stack>
        </Card>
    )
}