import { Card, CardOverflow, Divider, Typography } from "@mui/joy";
import "./styles.scss";

export const SearchResultCard = ({ title, desc, domain, icon }) => {
    return (
        <div className="search-result-card">
            <Card variant="outlined" sx={{ width: 720 }}>
                <Typography level="h2" sx={{ fontSize: 'md', mt: 0.5 }}>{ title }</Typography>
                <Typography level="body2" sx={{ mt: 1, mb: 2 }}>{ desc }</Typography>

                <CardOverflow 
                    variant="soft"
                    sx={{
                        display: 'flex',
                        gap: 1.5,
                        py: 1.5,
                        px: 'var(--Card-padding)',
                        bgcolor: 'background.level1',
                    }}
                >
                    <img width={16} src={ icon }/>
                    <Divider orientation="vertical" />
                    <Typography level="body3" sx={{ fontWeight: 'md', color: 'text.secondary' }}>{ domain }</Typography>
                </CardOverflow>
            </Card>
        </div>
    )
}