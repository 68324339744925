import "./styles.scss";

export const Headerbar = ({ action=[], lead=[] }) => {
    return (
        <div className={"headerbar"}>
            <div className="headerbar-lead">
                {
                    lead.map((item) => item)
                }
            </div>
            <div className="headerbar-action"> 
                {
                    action.map((item) => item)
                }
            </div>
        </div>
    )
}