import { Button, FormControl, FormLabel, Option, Select, Typography } from "@mui/joy";
import * as Icons from "react-icons/io5";
import { useEffect, useState } from "react";
import { PreferencesAPI } from "@api";
import { toast } from "react-toastify";

const { View } = require("@components")

export const SettingsScreen = () => {
    const [config, setConfig] = useState({
        classifyServiceCron: "0"
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        let config = await PreferencesAPI.getConfig();

        setConfig(config.data);
    }

    const handleSave = async () => {
        try {
            await PreferencesAPI.updateConfig({...config});

            toast("Đã lưu cài đặt thành công!");
        }catch(e) {
            toast.error("Không thể lưu cài đặt!");
        }
    }

    return (
        <View
            headerbar={{
                action: [
                    <Button 
                        size="sm"
                        startDecorator={<Icons.IoSave/>}
                        onClick={handleSave}
                    >Lưu cài đặt</Button>
                ]
            }}
        >
            <Typography level="h3" mb={3} fontWeight={600}>Cài đặt</Typography>
            <Typography level="h6" mb={3} fontWeight={600}>Tiến trình phân loại</Typography>
            <FormControl sx={{width: 240}}>
                <FormLabel>Chạy lại tiến trình sau</FormLabel>
                <Select 
                    value={config.classifyServiceCron}
                    onChange={(e, value) => setConfig(config => ({...config, classifyServiceCron: value}))}
                >
                    <Option value="0">Tắt</Option>
                    <Option value="1">1 tiếng</Option>
                    <Option value="2">2 tiếng</Option>
                    <Option value="3">3 tiếng</Option>
                    <Option value="4">4 tiếng</Option>
                    <Option value="5">5 tiếng</Option>
                    <Option value="6">6 tiếng</Option>
                    <Option value="7">7 tiếng</Option>
                    <Option value="8">8 tiếng</Option>
                    <Option value="9">9 tiếng</Option>
                    <Option value="10">10 tiếng</Option>
                    <Option value="11">11 tiếng</Option>
                    <Option value="12">12 tiếng</Option>
                    <Option value="13">13 tiếng</Option>
                    <Option value="14">14 tiếng</Option>
                    <Option value="15">15 tiếng</Option>
                    <Option value="16">16 tiếng</Option>
                    <Option value="17">17 tiếng</Option>
                    <Option value="18">18 tiếng</Option>
                    <Option value="19">19 tiếng</Option>
                    <Option value="20">20 tiếng</Option>
                    <Option value="21">21 tiếng</Option>
                    <Option value="22">22 tiếng</Option>
                    <Option value="23">23 tiếng</Option>
                    <Option value="24">24 tiếng</Option>
                </Select>
            </FormControl>
        </View>
    )
}